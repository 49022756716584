import React, { useState } from "react";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import FillChoicesCTA from "./personal-statement-and-choices-cta";
import CompleteAutomation from "./complete-automation";
import ShowLastStatus from "../show-last-status";
import { Checkbox } from "antd";
import { APPLICATION_SECTION } from "src/constants/applications.constants";
import { DirectApplyCreators } from "src/redux/actions";

const PersonalStatementAndChoices = ({
  t,
  isUCASAccountCreated,
  ucasQuickSubmit,
  requestMarkComplete,
  application,
}) => {
  const {
    choices_form_completed_at,
    choices_form_complete,
    choices_form_error_message,
    choices_form_job_status,
    jobs_running,
  } = ucasQuickSubmit;

  const [hasUcasCredentials, setHasUcasCredentials] = useState(false);

  const handleCheckboxChange = e => {
    setHasUcasCredentials(e.target.checked);
    const params = {
      section: APPLICATION_SECTION.CHOICES,
      complete: !choices_form_complete,
    };
    requestMarkComplete(application?.id, params);
  };

  return (
    <div
      style={{ width: "25%" }}
      className="flex flex-col border rounded bg-white mx-2 relative"
    >
      <div className="border-b text-base font-bold p-4 text-center truncate">
        {t("direct_apply_apps.quick_submit.choicesAndPS.title")}
      </div>
      <div className="flex flex-col items-center p-4">
        <span className="text-sm text-gray-500 pb-4 text-center">
          {t("direct_apply_apps.quick_submit.choicesAndPS.subTitle")}
        </span>
        {choices_form_job_status === "Partially Completed" &&
        !choices_form_complete &&
        !jobs_running ? (
          <div className="flex mb-4">
            <div className="w-6 flex">
              <Checkbox
                onChange={handleCheckboxChange}
                defaultChecked={choices_form_complete}
                className="gray-900"
              />
            </div>
            <div className="flex-1 pl-1 flex">
              <span className="font-body font-normal text-base text-gray-750 leading-6 whitespace-pre-wrap text-left">
                I have verified details in this section
              </span>
            </div>
          </div>
        ) : (
          <CompleteAutomation
            isUCASAccountCreated={isUCASAccountCreated}
          />
        )}
        <FillChoicesCTA isUCASAccountCreated={isUCASAccountCreated} />
        <div className="my-4 mb-8" />
        <ShowLastStatus time={choices_form_completed_at} />
      </div>
    </div>
  );
};

const mapStateToProps = state => ({
  ucasQuickSubmit: state.directApply.ucasQuickSubmit,
  application: state.directApply.selected,
});

const mapDispatchToProps = {
  requestMarkComplete: DirectApplyCreators.requestMarkComplete,
};

export default withTranslation()(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(PersonalStatementAndChoices),
);
